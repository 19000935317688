import React from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import {
    Box,
    Heading,
    Flex,
} from '@chakra-ui/react'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { RichText } from 'prismic-reactjs'
import { linkResolver } from '../../utils/linkResolver'

const Event = (props) => {
     
    return (
            <Box>
                <Flex w="100%">
                <Box w={{lg: '50%'}} p="4">
                    <GatsbyImage image={getImage(props.eventImage)} />   
                </Box>
                <Box w={{lg: '50%'}} p="4">
                    <Flex flexDirection="column" justifyContent="center" h="100%">
                        <Heading as="h2" fontSize={{ sm: '1.75rem', md: '2rem', lg: '2.5rem'}} mb="2">
                            {props.eventTitle}
                        </Heading>
                        <RichText render={props.eventDescription} linkResolver={linkResolver} />
                        {/* <Text className="member-title">
                            {props.eventDescription}
                        </Text> */}
                        <a href={props.eventLink} style={{fontSize: '1.25rem'}}>
                            {props.buttonLabel} <ArrowForwardIcon />
                        </a>
                    </Flex>
                </Box>
                </Flex>
            </Box>
    )
}

export default Event