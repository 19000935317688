import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import {
    Box,
} from '@chakra-ui/react'
import Event from './Event'

const EventList = (props) => {

    const data = useStaticQuery(graphql`
    {
        allPrismicEvents(sort: {fields: first_publication_date, order: ASC}) {
          edges {
            node {
              data {
                event_title {
                  text
                }
                event_link_label {
                  text
                }
                event_link {
                  url
                  target
                }
                event_image {
                  alt
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        placeholder: BLURRED,
                        layout: FULL_WIDTH,
                        aspectRatio: 1.5,
                        transformOptions: {cropFocus: CENTER}
                        )
                    }
                  }
                }
                event_description {
                  text
                  richText
                }
              }
            }
          }
        }
      }
  `)

  const document = data.allPrismicEvents.edges
     
    return (
        <Box>
            <Box >
                {document.map((eventItem) => {
                    return (
                        <Box my="8">
                        <Event 
                            eventTitle={eventItem.node.data.event_title.text}
                            eventDescription={eventItem.node.data.event_description.richText}
                            eventLink={eventItem.node.data.event_link.url}
                            eventLinkTarget={eventItem.node.data.event_link.target}
                            buttonLabel={eventItem.node.data.event_link_label.text}
                            eventImage={eventItem.node.data.event_image.localFile}
                        />
                        </Box>
                    )
                })}
            </Box>
        </Box>
    )
}

export default EventList